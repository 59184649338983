.App {
    font-family: Arial, sans-serif;
  }
  
  .feedback {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    background-color: #0066ff;
    padding: 10px;
    color: white;
    writing-mode: vertical-rl;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    backface-visibility: hidden;
    z-index: 99 !important;
  }
  
  .feedback p {
    margin: 0;
  }
  
  .popup {
    position: fixed;
    left: -300px; /* Start hidden off-screen */
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    box-sizing: border-box;
    transition: left 0.3s ease;
    z-index: 100 !important;
  }
  
  .popup.active {
    left: 0; /* Slide in */
  }
  
  .popup h2 {
    margin-top: 0;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .custom-file-input {
    display: none;
  }
  
  .custom-file-label {
    display: inline-block;
    background-color: #0066ff;
    color: white;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .file-name {
    margin-left: 10px;
    font-size: 14px;
  }