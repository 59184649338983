/* News.css */
.news-detail {
    max-width: 100%;
    margin: 10px auto;
    padding: 10px;
}

.news-detail img {
    width: 100%;         
    height: auto;        
    max-height: none;  
    object-fit: contain; 
}

.news-detail h1 {
    font-size: 28px;
    color: #fff;
    margin: 20px 0;
}

.news-detail p {
    font-size: 18px;
    line-height: 1.6;
    color: #fff;
}

/* Responsive */
@media (max-width: 768px) {
    .news-detail {
        padding: 15px;
    }

    .news-detail h1 {
        font-size: 24px;
    }

    .news-detail p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .news-detail {
        padding: 10px;
    }

    .news-detail h1 {
        font-size: 20px;
    }

    .news-detail p {
        font-size: 14px;
    }
}
