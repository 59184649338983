/* cart css */
.cart-container img {
    max-width: 100%;
    height: auto;
}

.cart-container {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 120px;
    z-index: 9999999 !important;
}

.cart-container:not(.left) {
    right: 15px;
    bottom: 30px;
}

.cart-container a {
    display: block;
}

.cart-container span {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f59802;
    position: relative;
}

.zoomIn_cart {
    animation-name: zoomIn_cart;
}

.animated_cart {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animated_cart.infinite {
    animation-iteration-count: infinite;
}

.cmo-cart-circle {
    width: 50px;
    height: 50px;
    top: -5px;
    right: -5px;
    position: absolute;
    background-color: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid rgba(220, 105, 11, 0.8);
    border-color: #f3cb06;
    opacity: .5;
}

.cmo-cart-circle-fill {
    width: 60px;
    height: 60px;
    top: -10px;
    position: absolute;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-color: rgba(252, 170, 17, 0.45);
    opacity: .75;
    right: -10px;
}

.pulse_cart {
    -webkit-animation-name: pulse_cart;
    animation-name: pulse_cart;
}

.left {
    right: 0;
}

a:where(:not(.wp-element-button)) {
    text-decoration: none;
}

@keyframes zoomIn_cart {
    from {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

@-webkit-keyframes pulse_cart {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse_cart {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.counter-container {
    position: absolute;
    background-color: red;
    z-index: 99;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
}

.counter-container p {
    color: #fff;
    position: relative;
    top:7px;
    font-size:13px
}