.bank-info {
    background: #f8fafb;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
    text-align: left;
}

.bank-info h4 {
    margin-bottom: 10px;
    color: #d32f2f; /* Màu đỏ */
    text-align: center;
}

.account-number {
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
}

.qr-code img {
    max-width: 230px;
}