/* Nền background */
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 5%;
}

/* Profile Card */
.profile-card {
  width: 700px;
  max-width: 90%;
  padding: 25px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(12px);
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
  text-align: center;
}

/* Header */
.profile-header {
  font-size: 22px;
  font-weight: bold;
  color: #fff !important;
}

/* Avatar + Change Photo */
.avatar-section {
  text-align: center;
}

.avatar-container {
  position: relative;
  display: inline-block;
}

.profile-avatar {
  border: 4px solid #1890ff;
  width: 140px;
  height: 140px;
  transition: all 0.3s ease-in-out;
}

/* Đưa nút "Change Photo" vào trong Avatar */
.change-photo-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Tên User */
.profile-name {
  font-size: 28px;
  font-weight: bold;
}

.highlight-name {
  background: #1890ff;
  color: white;
  padding: 3px 8px;
  border-radius: 5px;
}

/* Role */
.profile-role {
  display: block;
  margin-bottom: 10px;
  font-size: 18px; 
  color: #fff !important;
}

/* Edit Profile Button */
.edit-profile-btn {
  font-size: 16px;
  margin-top: 20px;
}

/* Tabs */
.profile-tabs {
  margin: 20px 0;
  text-align: center;
  font-size: 18px;
}

.profile-tabs .active-tab {
  font-weight: bold;
  color: #1890ff;
  margin-right: 15px;
}

.profile-tabs .inactive-tab {
  color: #999;
}

/* Thông tin cá nhân */
.profile-info {
  text-align: left;
  padding: 10px;
  font-size: 18px;
  color: white;
}

/* ======= RESPONSIVE DESIGN ======= */

/* Màn hình nhỏ (dưới 768px - Mobile) */
@media (max-width: 768px) {
  .profile-card {
    width: 90%;
    padding: 20px;
  }

  .profile-avatar {
    width: 100px;
    height: 100px;
  }

  .change-photo-btn {
    width: 25px;
    height: 25px;
    bottom: 0;
    right: 0;
  }

  .profile-name {
    font-size: 22px;
  }

  .profile-role {
    font-size: 16px;
  }

  .profile-info {
    font-size: 16px;
  }
}

/* Màn hình rất nhỏ (dưới 480px - Mobile nhỏ) */
@media (max-width: 480px) {
  .profile-container {
    flex-direction: column;
  }

  .profile-name {
    order: 1;
    font-size: 22px;
  }

  .avatar-container {
    order: 2;
    margin: 10px 0;
  }

  .profile-avatar {
    width: 120px;
    height: 120px;
  }

  .edit-profile-btn {
    order: 3;
    margin-top: 10px;
  }

  .profile-info {
    order: 4;
    font-size: 14px;
  }
}
