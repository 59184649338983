.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:  1000,
}

.modal-content {
  background: #fff !important;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  width: 400px !important;
  text-align: center;
  position: relative;
  overflow-y: scroll;
  max-height: 70vh;
}

.btn-closeModal {
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    background: #ff4d4d;
    color: white;
    cursor: "pointer";
    border-radius: "5px";
    transition: "background 0.2s";
}