.body-content {
    border: 0.5px solid #DDD;
    padding: 0.3em;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.4), 0 6px 20px 0 rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    margin-top: 50px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    min-height: 80vh;
}
