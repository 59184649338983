.product-container {
  width: 99%; /* Giới hạn chiều rộng để không bị quá to */
  max-width: 1900px; /* Giữ danh sách sản phẩm không bị giãn quá rộng */
  margin: auto;
  text-align: center;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Căn giữa danh sách sản phẩm */
  gap: 15px; /* Khoảng cách giữa các sản phẩm */
  padding: 20px 0 20px 0;
}

.product-card {
  width: 160px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  transition: transform 0.3s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-title {
  font-size: 16px;
  font-weight: bold;
  min-height: 40px; /* Đảm bảo tiêu đề không bị nhảy dòng quá nhiều */
}

.product-description {
  font-size: 14px;
  min-height: 60px; /* Đảm bảo phần mô tả đều nhau */
}

.price {
  font-size: 18px;
  color: red;
  font-weight: bold;
}

.number {
  font-size: 16px;
  color: #212121;
  font-weight: 600;
}

.add-to-cart {
  background-color: #ff9800;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  border-radius: 5px;
}

.add-to-cart:hover {
  background-color: #e68900;
}

/* Giữ nguyên chiều cao cho phần mô tả và tiêu đề */
.product-title {
  min-height: 50px; /* Đặt chiều cao tối thiểu */
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.product-description {
  flex-grow: 1; /* Đảm bảo mô tả mở rộng để các phần còn lại đều nhau */
  min-height: 80px;
  font-size: 14px;
  color: #555;
}

/* Căn chỉnh giá */
.price {
  color: red;
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0;
}

/* Đảm bảo nút nằm ở cuối */
.add-to-cart {
  background: orange;
  border: none;
  padding: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-top: auto; /* Đẩy nút xuống cuối */
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;
  
}

.filter-container select {
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  background-color: #fff;
  color: #333;
  border: 2px solid #007bff;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.pagination button:hover {
  background-color: #007bff;
  color: white;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
  box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.6);
}

.pagination button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  border-color: #ccc;
}

/* 📱 Responsive */
@media (max-width: 768px) {
  .product-list {
      flex-direction: column;
      align-items: center;
  }

  .product-card {
      width: 90%;
      max-width: 300px;
  }
}

@media (max-width: 480px) {
  .product-card {
      width: 100%;
      max-width: 250px;
  }
}
