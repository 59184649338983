.container {
    width: 100%;
    /* max-width: 1600px; */
    margin: 0 auto;
    padding: 20px;
}

@media (min-width: 1920px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1800px !important;
    }
}
