.news-container {
  display: flex;
  gap: 20px;
  align-items: stretch;
  width: 100%;
  margin-bottom: 30px;
}

.news-card {
  border-radius: 10px;
  overflow: hidden;
  background: white;
  text-decoration: none; 
  color: inherit; 
  transition: transform 0.2s ease-in-out;
}

.news-card:hover {
  transform: scale(1.05); /* Hiệu ứng phóng to nhẹ khi hover */
}


/* Bài viết nổi bật chính */
.main-featured {
  flex: 2;
  background: rgba(212, 63, 63, 0.63);
  padding: 20px;
  border-radius: 10px;
  text-decoration: none; 
  color: inherit; 

}

.main-featured img {
  width: 100%;
  height: 475px;
  object-fit: cover;
  border-radius: 5px;
}

.featured-info {
  font-size: 20px;
  margin-bottom: 10px;
}
.featured-info h2 {
  font-size: 1.5em;
}

.featured-info a {
  color: blue;
  text-decoration: underline;
}

.featured-info h3 {
  font-size: 18px;
  margin: 5px 0;
}

.featured-info small {
  font-size: 12px;
  color: #f1f1f1;
}

.featured-info p {
  font-size: 14px;
  color: #ddd;
}

/* Sidebar - Danh sách bài viết nổi bật */
.sidebar {
  flex: 1; 
  background:#fff;
  padding: 15px;
  min-height: 350px;
  overflow-y: scroll;
  border-radius: 10px;
}

.sidebar h3 {
  font-size: 20px;
  color: #333; 
  margin-bottom: 10px;
  font-weight: bold;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.sidebar-item:last-child {
  border-bottom: none;
}

.sidebar-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.sidebar-item span {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  line-height: 1.4;
}

/* Thêm hiệu ứng hover */
.sidebar-item:hover {
  background: rgba(251, 30, 30, 0.1);
  border-radius: 6px;
  transition: 0.3s;
  cursor: pointer;
}

/* Phần bài viết mới nhất */
.latest-news {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  width: 100%;
  padding: 15px;
  margin-left: 10px;
}

.news-item {
  flex: 1 1 calc(16.66% - 20px); 
  max-width: calc(16.66% - 20px);
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  min-height: 320px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.news-item h4 {
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}

.news-item small {
  color: gray;
  font-size: 12px;
}

.news-item p {
  font-size: 14px;
  margin-top: 10px;
  color: #333;
  flex-grow: 1;
}

.news-item:last-child {
  flex: 1 1 calc(16.66% - 20px);
  max-width: calc(16.66% - 20px);
}

/* Responsive cho Tablet */
@media (max-width: 1024px) {
  .news-item {
    flex: 1 1 calc(50% - 10px); /* 2 cột thay vì 3 */
    max-width: calc(50% - 10px);
  }
  .news-item:last-child {
    flex: 1 1 calc(50% - 20px); /* 2 cột thay vì 3 */
    max-width: calc(50% - 10px);
  }
  .main-featured img {
    margin-bottom: 10px;
    height: 300px;
}
.featured-info h2 {
  font-size: 1.2em;
}
.featured-info {
  font-size: 15px;
}
.latest-news {
  margin-left: 0;
}

}

/* Responsive cho Mobile */
@media (max-width: 768px) {
  .news-container{
    max-height: 55vh;
    margin-bottom: 20px;
  }
  .news-item {
    flex: 1 1 100%; /* 1 cột trên màn nhỏ */
    max-width: 100%;
  }
  .news-item:last-child {
    flex: 1 1 100%; /* 1 cột trên màn nhỏ */
    max-width: 100%;
  }
  .main-featured {
    flex: 1 1;
    background: rgb(237 237 237 / 35%);
    padding: 10px;
    border-radius: 10px;
}
.main-featured img {
  height: 125px;
}
.featured-info h2 {
  font-size: 0.8em;
}
.sidebar-item span {
  font-size: 12px;
  color: #333;
  line-height: 1.2;
}
.latest-news {
  margin-left: 0;
}
}