.container {
    align-items: center;
    justify-self: center
}
.main-container{
    z-index: -10;
}

.layout {
    padding-top: 15vh !important;
    padding: 10px;
}


@media (max-width: 480px) {
    .layout {
        padding-top: 8vh !important;
    }
}