.active{
  
}

nav {
  display: flex;
  justify-content:space-between;
  align-items:center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  height: 60px;
}

nav .title{
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

nav ul {
  display: flex;
  align-items: center; /* Căn giữa theo chiều dọc */
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
}

/* nav ul li a:not(.active):hover{
  background-color: #172554
} */

nav .menu {
  display: none;
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 1.5em;
  
}

nav .menu span{
  height: 0.2rem;
  width: 100%;
  background-color: #FFF;
  border-radius: 0.2rem;
}

nav .profile{
  display:flex;
  align-items: center;
  /* border: 1px solid #ffffff33; */
  border-radius: 10px;
  justify-content: center;
}

nav .profile .icon{
  color: #fff;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%; /* Cách menu cha một khoảng bằng chiều cao của nó */
  left: 0;
  background: #333 !important; 
  color: #fff;
  min-width: 200px;
  border-radius: 5px;
  list-style: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
}

/* Hiển thị dropdown khi hover */
.dropdown:hover .dropdown-menu{
  display: block;
}

/* Căn chỉnh dropdown */
.dropdown-menu li {
  padding: 10px 15px;
}

.dropdown-menu li a {
  color: #fff;
  text-decoration: none;
  display: block;
  width: 100%;
}

.dropdown-menu li:hover {
  background: #555;
}


@media (max-width: 768px) {
  /* 🔹 Hiển thị menu toggle (nút 3 gạch) */
  nav .menu {
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 22px;
      cursor: pointer;
  }

  /* 🔹 Ẩn menu khi chưa mở */
  nav ul {
    position: fixed;
    top: 0;
    left: -250px; /* Ẩn ngoài màn hình */
    width: 250px;
    height: 100vh;
    background: #333;
    color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease-in-out;
    z-index: 1000;

  }

  /* 🔹 Khi mở menu, trượt vào */
  nav ul.open {
    left: 0;
    display: block;
  }

  nav ul li {
    padding-block: 15px;
    border-bottom: 1px solid #333;
  }

  nav ul li a {
      margin: 0.2rem 0.5rem;
      color: white;
      display: block;
  }
  .dropdown .dropdown-menu{
    display: block;
    position: relative;
    height: 140px;
    background: none;
    margin-top: 10px;
    background-color: #333;
   }
   .profile-dropdown .dropdown-menu {
    display: block !important;
    position: relative;
    background: none;
  }

  .dropdown.active .dropdown-menu {
    display: block;
  }

  /* ✅ Định dạng dropdown */
  .dropdown-menu li {
    padding: 8px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  nav .profile{
    display:flex;
    justify-content: flex-start;
    padding-left: 10px;
  }
}
