/* CSS giỏ hàng (Cart.css) */
.cart-box {
    position: relative; 
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    color: #333;
    min-height: 80vh;
  }
  
  /* Tiêu đề giỏ hàng */
  .cart-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 2px solid #ddd;
    display: flex  ;
    align-items: center;
    justify-content: space-around;
  }
  
  /* Bảng sản phẩm */
  .cart-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .cart-table th, .cart-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Cột sản phẩm */
  .cart-item {
    /* display: flex; */
    align-items: center;
    gap: 10px;
  }
  
  .cart-item img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  /* Số lượng sản phẩm */
  .cart-quantity {
    /* display: flex; */
    align-items: center;
    gap: 5px;
  }
  
  .cart-quantity input {
    width: 50px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px;
  }
  
  /* Nút xóa sản phẩm */
  .remove-btn {
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  .remove-btn:hover {
    background: darkred;
  }
  
  /* Tổng tiền và nút thanh toán */
  .cart-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .cart-footer h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .btn-pay {
    background: blue;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  .btn-pay:hover {
    background: darkblue;
  }
  